import Layzr from 'layzr.js'
const layzr = Layzr({
    normal: 'data-defer-src',
    retina: 'data-defer-retina',
    srcset: 'data-defer-srcset',
    threshold: 100,
})
const init = () => {
    layzr.update().check().handlers(true)
}
// TODO: remove
window.layzr = layzr;
if (document.readyState !== 'loading') init();
else document.addEventListener('DOMContentLoaded', init);

export default layzr;