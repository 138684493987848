
export default function ViewPortWatcher (options) {

    if (typeof options === 'undefined') {
        options = {};
    }

    var viewPortClasses = options.viewPortClasses || [
        'mobile-portrait',       // mobile-portrait  : 420px
        'mobile-landscape',      // mobile-landscape : 576px
        'tablet-portrait',       // tablet-portrait  : 768px
        'tablet-landscape',      // tablet-landscape : 992px
        'desktop-small',         // desktop-small    : 1200px  
        'desktop-large',         // desktop-large    : 1600px  
    ];

    var breakpoints = options.breakpoints || [
        420,
        576,
        768,
        992,
        1200,
        1600,
    ];

    this.props = {
        'elementToWatch': options.elementToWatch || document.body,
        'viewPortClasses': viewPortClasses,
        'breakpoints': breakpoints,
        'defaultClass': options.defaultClass || viewPortClasses[0],
        'highestMobileClassIndex': options.highestMobileClassIndex || 2,
    };

    // state ;)
    this.viewPortClass = this.props.viewPortClasses[0];
    this.callbacks = [];
    this.isMobile = true;
 

    var classDetected = window.getComputedStyle(this.props.elementToWatch, ':before')
                            .content.replace(/^['"]+|\s+|\\|(;\s?})+|['"]$/g, '');
    console.log('viewPortClass detected onload:', classDetected);

    this.viewPortClass = this.props.viewPortClasses.indexOf(classDetected) >= 0
        ? classDetected
        : this.props.defaultClass;

    this.isMobile = this.props.viewPortClasses.indexOf(this.viewPortClass) <= this.props.highestMobileClassIndex;

    console.log('viewPortClass set:', this.viewPortClass);

    // this.create = function(options) {
    //     return new ViewPortWatcher(options);
    // };


    
    this.addCallback = function(cb) {
        this.callbacks.push(cb);
    };
    
    this.executeCallbacks = function(cbs, viewPortClass, isMobile) {
        if (!cbs || cbs.length < 1) return;
        cbs.forEach(function(cb) {
            if (typeof cb === 'function') {
                cb(viewPortClass, isMobile);}
        });
    };

    this.onViewportClassChange = function(event) {
        var viewPortClass = this.props.viewPortClasses.indexOf(event.animationName) >= 0
            ? event.animationName
            : this.viewPortClass;
        var isMobile = this.props.viewPortClasses.indexOf(viewPortClass) <= this.props.highestMobileClassIndex;
        this.viewPortClass = viewPortClass;
        this.isMobile = isMobile; 
        this.executeCallbacks(this.callbacks, viewPortClass, isMobile);
    
        console.log('viewPortClass change:', this.viewPortClass);
    };    

    document.addEventListener('animationend', this.onViewportClassChange.bind(this), false);

}

// ViewPortWatcher.create()
// var viewPortWatcher = new ViewPortWatcher();
