import axios from 'axios';
import Glider from 'glider-js';
// TODO: path var, or alias or anythigy
import Popover from '../popover/popover.js';
import { ViewPortWatcher } from "App/ViewPortWatcher"
import { AutoComplete } from "Vendor/AutoComplete"
import MicroModal from 'Vendor/micromodal/micromodal.js';
// import ImageDefer from '../../image-defer';
import { layzr } from 'Vendor/init-layzr';
import { t } from "App/ITI18n"
import { onGliderSlideVisible } from 'App/GliderHelper'

(function(context) {
    'use strict';

    // Constructor
    context.HomePage = function(options) {
        // this.props = {
        // }
        this.state = {
            popoverLoading: false,
            popoverContent: '',
            popover: null,
            searchTerms: [],
        }
        this.setState = setState.bind(this);
        applyState.call(this, this.state);
        init.call(this);

    }   
    
    function setState(stateObj, callback) {
        var prevState = Object.assign({}, this.state);
        this.state = Object.assign(this.state, stateObj);
        applyState.call(this, this.state, prevState, callback);
        // console.log('this.state', this.state);
    }


    function applyState(state, prevState, callback) {
        var prevState = (typeof prevState === 'object') ? prevState : state;
        if (JSON.stringify(prevState.searchTerms) !== JSON.stringify(state.searchTerms)) {
            renderSearchTerms.call(this, state.searchTerms)
        }
        if (typeof callback === 'function') callback();
    }    

    function renderSearchTerms(terms) {
        const termsNode = document.querySelector('.main-search__terms');
        renderSearchTermLabels.call(this, termsNode, terms);
        renderSearchTermInputs.call(this, terms);
    }

    function renderSearchTermLabels(parent, terms) {
        if (parent.classList.contains('mobile') && this.this.viewPortWatcher.isMobile) {
            parent.style.display = (terms.length) ? 'block' : 'none';
        }
        [].slice.call(parent.querySelectorAll('span')).forEach(el =>
            parent.removeChild(el)
        );
        terms.forEach(term => {
            const termText = document.createTextNode(term.title);
            const termNode = document.createElement("span");
            termNode.classList.add('main-search__term');
            termNode.classList.add('input-tag');
            termNode.appendChild(termText);
            termNode.setAttribute('data-value', term.value);
            termNode.setAttribute('data-title', term.title);
            termNode.addEventListener('click', (e) => {
                removeTerm.call(this, {
                    title: e.target.getAttribute('data-title'), 
                    value: e.target.getAttribute('data-value') 
                });
            })
            parent.appendChild(termNode);
        });
        if (terms.length && parent.classList.contains('mobile')) {
            this.state.popover.move();
        }
    }
    
    function renderSearchTermInputs(terms) {
        const termInputsNode = document.querySelector('.main-search__locations');
        [].slice.call(termInputsNode.querySelectorAll('input')).forEach(el =>
            termInputsNode.removeChild(el)
        );
        terms.forEach(term => {
            const termInputNode = document.createElement("input");
            termInputNode.setAttribute('type', 'hidden');
            termInputNode.setAttribute('name', 'location[]');
            termInputNode.setAttribute('value', term.value);
            termInputsNode.appendChild(termInputNode);
        });
    }

    function loadMainSlideImg(viewPortClass, isMobile) {
        if (isMobile) return;
        // var images = document.querySelectorAll(".hero-slide__picture img, .hero-slide__banner img");
        // [].slice.call(images).forEach(el =>
        //     el.setAttribute('src', el.getAttribute('data-src'))
        // );
        var sources = document.querySelectorAll(".hero-slide__picture source, .hero-slide__banner source");
        [].slice.call(sources).forEach(el =>
            el.setAttribute('srcset', el.getAttribute('data-srcset'))
        );
        layzr.update();
    }

    function initMainSlideImg() {
        this.viewPortWatcher.addCallback(loadMainSlideImg);
        loadMainSlideImg(this.viewPortWatcher.viewPortClass, this.viewPortWatcher.isMobile);
    }



    function initConstructionsSlider() {

        document.querySelector('.construction-carousel .glider').addEventListener(
            'glider-loaded', 
            onGliderLoaded
        );

        this.glider = new Glider(document.querySelector('.construction-carousel .glider'), {
            itemWidth: 273,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
            dragVelocity: 1,
            scrollLock: true,
            scrollLockDelay: 0,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            },
            dots: '.construction-carousel .glider-dots',
            responsive: [
                {
                    // screens greater than >= this.viewPortWatcher.props.breakpoints[2]
                    breakpoint: this.viewPortWatcher.props.breakpoints[2],
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        itemWidth: 273,
                        duration: 0.25
                  }
                },
                {
                    breakpoint: this.viewPortWatcher.props.breakpoints[3],
                    settings: {
                        draggable: true,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        itemWidth: 273,
                        duration: 0.25
                  }
                },
                {
                    breakpoint: this.viewPortWatcher.props.breakpoints[4],
                    settings: {
                        draggable: true,
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        itemWidth: 273,
                        duration: 0.25
                  }
                },
                {
                    breakpoint: this.viewPortWatcher.props.breakpoints[5],
                    settings: {
                        draggable: true,
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        itemWidth: 273,
                        duration: 0.25
                  }
                }
              ]
        });

        document.querySelector('.construction-carousel .glider').addEventListener(
            'glider-slide-visible', 
            onGliderSlideVisible
        );


        // function refreshGlider() {
        //     glider.refresh(true);
        // }


        function onGliderLoaded() {
            // glider.refresh(true);
            document.querySelectorAll('.construction-carousel .glider .glider-item.visible .construction__data img').forEach(function(item) {
                if (!item.getAttribute('src')) {
                    item.setAttribute('src', item.getAttribute('data-src'));
                }
            });
        }




    }

    function autoPlaySlider(dotsSelector, delay = 10000) {
        const dots = [...dotsSelector];
        let i = 0;
        setInterval(() => {
            dots[i].click();
            i = i === (dots.length - 1) ? 0 : i + 1;
        }, delay);
    }

    function initAutoComplete() {

        var ac = new AutoComplete({
            selector: '#searchLocation',
            minChars: 2,
            source: function(term, callback){
                axios.get('/api/autocomplete/location?query=' + term)
                .then(function(result) {
                    callback(result.data.slice(0, 25));
                    if (result.data.length > 0 && this.state.popover && this.state.popover.isVisible) {
                        this.state.popover.hide();
                    }
                }.bind(this))
                .catch(function(error) {
                    callback(error.response);
                }.bind(this));
            }.bind(this),
            renderItem: function (item, search){
                var se = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                var re = new RegExp("(" + se.split(' ').join('|') + ")", "gi");
                var styledItem = item.locationName.replace(re, "<strong>$1</strong>");
                return '<div class="autocomplete-suggestion" data-link="'+ item.locationNamelink +'" data-val="'+ item.locationName +'">'+ styledItem +'</div>';
            },
            onSelect: function(event, term, item) {
                event.preventDefault();
                event.stopImmediatePropagation();
                addTerm.call(this, { 
                    title: item.getAttribute('data-val'), 
                    value: item.getAttribute('data-link') 
                });
                var input = document.querySelector('#searchLocation');
                input.value = "";
            }.bind(this),
        });
    }

    function initSettlementPopOver() {
        const trigger = document.getElementById('searchLocation');
        trigger.addEventListener('click', () => {popoverToggle.call(this, trigger);});
        trigger.addEventListener('input', e => {
            if (this.state.popover && !this.state.popover.isVisible && e.target.value.length < 2) {
                this.state.popover.show();
            }
        });
    }

    function popoverToggle(trigger) {
        if (this.state.popover && this.state.popoverContent !== '') {
            this.state.popover.toggle();
            return;
        }
        axios.get('/v1/api/content/locationpopover')
        .then(function(result) {
            const popoverContent = createElementFromHTML(result.data);
            this.setState({ 
                popoverContent,
                popover: new Popover(trigger, popoverContent, { position: 'bottom', stayInViewport: false, }),
            }, 
                () => {
                    // new Tabber(
                    //     popoverContent.querySelectorAll('#locationPopoverContent .tab'),
                    //     popoverContent.querySelectorAll('#locationPopoverContent .tab-content'),
                    //     initPopoverTermsClick.call(this, popoverContent),
                    // );
                    // console.log('popoverContent', popoverContent);

                    
                    const tabs = popoverContent.querySelectorAll('.tabs__label');
                    [].slice.call(tabs).forEach( tab => {
                        tab.addEventListener('click', (e) => {
                            e.stopImmediatePropagation();
                        });
                    });

                    initPopoverTermsClick.call(this, popoverContent);
                }
            );
            this.state.popover.toggle();
        }.bind(this))
        .catch(function(error) {
            console.log(error.response || error);
        }.bind(this));
    }

    function createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();
        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild; 
    }

    function addTerm(term) {
        if (!this.state.searchTerms.find( searchTerm => searchTerm.value === term.value)) {
            const searchTerms = [ ...this.state.searchTerms, term ];
            this.setState({ searchTerms });
        }
    }

    function removeTerm(term) {
        const searchTerms = this.state.searchTerms.filter(searchTerm => searchTerm.value !== term.value );
        this.setState({ searchTerms });
    }

    // function addRemoveTerm(term) {
    //     const searchTerms = (this.state.searchTerms.find( searchTerm => searchTerm.value === term.value))
    //         ? this.state.searchTerms.filter(searchTerm => searchTerm.value !== term.value ) 
    //         : [ ...this.state.searchTerms, term ];
    //     this.setState({ searchTerms });
    // }

    function initPopoverTermsClick(popoverContent) {
        const terms = popoverContent.querySelectorAll('#locationPopoverContent a[data-searchterm-value]');
        [].slice.call(terms).forEach( term => {
            term.addEventListener('click', (e) => {
                e.stopImmediatePropagation();
                const target = ((e.target.parentElement.tagName.toLowerCase()) === 'a' )
                    ? e.target.parentElement
                    : e.target;
                addTerm.call(this, { 
                    title: target.getAttribute('data-searchterm-title'), 
                    value: target.getAttribute('data-searchterm-value') 
                });
            })
        });
    }

    function initMobileMenu() {
        const checkbox = document.querySelector('.header .hamburger__input');
        const header = document.querySelector('header.header');
        checkbox.addEventListener( 'change', function() {
            if(checkbox.checked) {
                header.style.bottom = 0;
            } else {
                header.style.bottom = 'initial';
            }
        });
    }



    function loadModal(type) {
        const requestConfig = {
            method: 'get',
            url: type === 'history'
                ? '/getLastseen'
                : '/addFavorites',
            params: {
                type: 'home',
            },
        };
        axios(requestConfig)
        .then(function(result) {
            const container = document.querySelector('.modal-container');
            container.innerHTML = result.data.trim();
            MicroModal.init();
            MicroModal.show('modal-default');
        }.bind(this))
        .catch(function(error) {
            error.response && console.log(error.response)
        }.bind(this));
    }

    function initModals() {

        const favoritesTrigger = document.querySelector('#favoritesButton');
        const historyTrigger = document.querySelector('#historyButton');

        favoritesTrigger.addEventListener( 'click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            loadModal.call(this, 'favorites');
        });        

        historyTrigger.addEventListener( 'click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            loadModal.call(this, 'history');
        });
    }


    function initSearchPricePrefix() {
        const tabsNode = document.querySelector('.main-search__tabs');
        const pricePrefixNode = document.getElementById('price-prefix');

        tabsNode.addEventListener('change', () => {
            pricePrefixNode.innerText = pricePrefixNode.innerText === t('price.millionPrefix','M')
                ? t('price.thousandPrefix','E')
                : t('price.millionPrefix','M');
        });
    }

    function initAll()  {
        this.viewPortWatcher = new ViewPortWatcher();
        initMainSlideImg.call(this);
        initConstructionsSlider.call(this);
        initAutoComplete.call(this);
        initSettlementPopOver.call(this);
        initMobileMenu();
        initModals.call(this);
        initSearchPricePrefix();
    }
    
    function init() {
        if ( document.readyState !== 'loading' ) {
            initAll.call(this);
        } else {
            window.addEventListener('DOMContentLoaded', function(){ initAll.call(this);}.bind(this));
        }
    }


    
})(window);

var homePage = new HomePage();
//es6 tests
// const func1 = () => {
//     console.log('es6 horray!')
// }
// func1()
// let { x, y, ...z } = { x: 1, y: 2, a: 3, b: 4 };
// console.log(x); // 1
// console.log(y); // 2
// console.log(z); // { a: 3, b: 4 }