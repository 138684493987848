(function(context) {  
    'use strict';

    var scrollOptions = { capture: false, passive: true };

    function onScroll(event) {
        //   document.querySelector('.homepage-slider').style.setProperty('--y', (-1 * window.scrollY)+'px');
        document.querySelector('.hero-slide__img').style.setProperty('top', (0.5 * window.scrollY) + 'px');
    }

    if ('IntersectionObserver' in window) {

        var referenceImage = document.querySelector('.hero-slide__img');

        if (!referenceImage) return;

        var observer = new IntersectionObserver(function (entries) {

            var intersectingCount = 0;

            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    intersectingCount += 1;
                } else {
                    intersectingCount -= 1;
                }
            });

            if (intersectingCount) {
                window.addEventListener('scroll', onScroll, scrollOptions);
            } else {
                window.removeEventListener('scroll', onScroll, scrollOptions);
            }
        });

        observer.observe(referenceImage);

    } else {
        window.addEventListener('scroll', onScroll, scrollOptions);
    }

})(window);