  export default class Popover {

    static isInViewport(element) {
        const rect = element.getBoundingClientRect();
        const html = document.documentElement;
        
        if (!this.stayInViewport) return true;
          
        return rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || html.clientHeight) &&
          rect.right <= (window.innerWidth || html.clientWidth);
    }

    constructor(trigger, content, { position = 'top', className = 'popover', stayInViewport = true, }) {
      this.trigger = trigger;
      this.position = position;
      this.className = className;
      this.content = content || document.createElement('div');
      this.stayInViewport = stayInViewport;
      this.orderedPositions = ['top', 'right', 'bottom', 'left'];
      this.popover = document.createElement('div');
      this.popover.appendChild(this.content);
  
      if (this.stayInViewport) {
        this.popover.classList.add()
      }
      const posProp = (this.stayInViewport) ? 'fixed' :  'absolute';
      Object.assign(this.popover.style, {
        position: posProp
      });
  
      this.popover.classList.add(className);
  
      this.handleWindowEvent = () => {
        if (this.isVisible) {
          this.show();
        }
      };
  
      this.handleDocumentEvent = (evt) => {
        if (
            this.isVisible 
            && evt.target !== this.trigger 
            && evt.target !== this.popover 
            && !this.popover.contains(evt.target)
        ) {
          this.popover.remove();
        }
      };

      this.move = this.move.bind(this);
      this.hide = this.hide.bind(this);
    }
  
    get isVisible() {
      return document.body.contains(this.popover);
    }

    move() {
      this.popover.classList.add('noanim');
      this.show();
    }
  
    show() {
      document.addEventListener('click', this.handleDocumentEvent);
      if (this.stayInViewport) {
        window.addEventListener('scroll', this.handleWindowEvent);
        window.addEventListener('resize', this.handleWindowEvent);
      }
  
      document.body.appendChild(this.popover);
  
      const { top: triggerTop, left: triggerLeft } = this.trigger.getBoundingClientRect();
      const { offsetHeight: triggerHeight, offsetWidth: triggerWidth } = this.trigger;
      const { offsetHeight: popoverHeight, offsetWidth: popoverWidth } = this.popover;
      const scrolledHeight = window.pageYOffset;
  
      const positionIndex = this.orderedPositions.indexOf(this.position);
  
      const positions = {
        top: {
          name: 'top',
          top: triggerTop - popoverHeight,
          left: triggerLeft - ((popoverWidth - triggerWidth) / 2)
        },
        right: {
          name: 'right',
          top: triggerTop - ((popoverHeight - triggerHeight) / 2),
          left: triggerLeft + triggerWidth
        },
        bottom: {
          name: 'bottom',
          top: (scrolledHeight + triggerTop) + triggerHeight,
          left: triggerLeft - ((popoverWidth - triggerWidth) / 2)
        },
        left: {
          name: 'left',
          top: triggerTop - ((popoverHeight - triggerHeight) / 2),
          left: triggerLeft - popoverWidth
        }
      };
  
      const position = this.orderedPositions
        .slice(positionIndex)
        .concat(this.orderedPositions.slice(0, positionIndex))
        .map(pos => positions[pos])
        .find(pos => {
          this.popover.style.top = `${pos.top}px`;
          this.popover.style.left = `${pos.left}px`;
          return Popover.isInViewport(this.popover);
        });
  
      this.orderedPositions.forEach(pos => {
        this.popover.classList.remove(`${this.className}--${pos}`);
      });
  
      if (position) {
        this.popover.classList.add(`${this.className}--${position.name}`);
      } else {
        this.popover.style.top = positions.bottom.top;
        this.popover.style.left = positions.bottom.left;
        this.popover.classList.add(`${this.className}--bottom`);
      }
    }
  
    hide() {
      this.popover.classList.remove('noanim');
      this.popover.remove();
  
      document.removeEventListener('click', this.handleDocumentEvent);
      window.removeEventListener('scroll', this.handleWindowEvent);
      window.removeEventListener('resize', this.handleWindowEvent);
    }
  
    toggle() {
      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    }
  }
