const onGliderSlideVisible = function (event, glider, preload = 3) {
    var index = Math.min(event.detail.slide, event.target._glider.slides.length - 1);
    loadGliderImages.call(event.target._glider.slides[index]);
    if (!glider) return
    var imgs_to_anticipate = preload;
    // var imgs_to_anticipate = 0;
    for (var i = 0; i <= imgs_to_anticipate; ++i){
        var index = Math.min(event.detail.slide + i, glider.slides.length - 1);
        loadGliderImages.call(glider.slides[index]);
        // loadGliderImages.call(glider.slides[index], glider.refresh());
        // console.log('glider.slides[index]', glider.slides[index]);
        // loadGliderImages.call(glider.slides[index]);
    }
}    

const loadGliderImages = function (callback) {
    [].forEach.call(this.querySelectorAll('img'),function(img){
        var _img = new Image(),  
        _src = img.getAttribute('data-src');
        _img.onload = function(){
            img.src = _src;
            img.classList.add('loaded');
            // if (typeof callback === "function") {
            //     console.log('callback', callback);
            //     callback(img);
            // }
        };
        if (img.src !== _src) {
            _img.src = _src;
        }
    });
}

export { onGliderSlideVisible, loadGliderImages }